const Company = [
  {
    name: "J.K.MEDICO PRIVATE LIMITED",
    fullName: "J.K.MEDICO PRIVATE LIMITED",
    phone: {
      primary: [
        {
          label: "primary",
          number: "",
        },
      ],
      alternate: [],
    },
    email: "jkmedico.pvt.ltd@gmail.com",
    address: [
      "J.K.MEDICO PRIVATE LIMITED, FIRST & SECOND FLOOR, PANCHRATNA BUILDING, JAWAHAR ROAD, OPP.MAHATMA GANDHI SCHOOL, RAJKOT-360001",
      "FIRST & SECOND FLOOR, PANCHRATNA BUILDING, JAWAHAR ROAD, OPP.MAHATMA GANDHI SCHOOL, RAJKOT-360001",
    ],
  },
];

const BankDetails = [
  {
    label: "Bank Details:",
    value: "KOTAK BANK",
  },
  {
    label: "A/C. No.:",
    value: "9824177044",
  },
  {
    label: "IFSC Code:",
    value: "KKBK0002793",
  },
];

export { Company, BankDetails };
import './App.css'
import Header from './Header';
import AllCompanies  from './AllCompanies';
// import Pfooter from './Pfooter';
import CustomFooter from './snippets/Customer/customFooter';

function CompanyList() {
  return (
    <div>
        <Header />
        <AllCompanies />
        <CustomFooter />
    </div>
  )
}

export default CompanyList